// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <div style={{ maxWidth: `70vw`, textAlign: `center`, verticalAlign: `center`, margin: `5rem auto 1.45rem auto`, paddingTop: `3rem`}}>
    <p>Hi, the best way to contact me is by sending e-mail to <a href="mailto:menezes.santos.rafael@gmail.com">menezes.santos.rafael [at] gmail [dot] com</a>.
        <br/>
        <br/>
        Alternatively, you can check the links for my profile on various websites in the top right corner.
        <br/>
        <br/>
        Hoping to hear from you soon!
    </p>
    </div>
  </Layout>
)

export default Contact